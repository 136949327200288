import { useEffect, useRef, useState } from 'react';
import HenkelLogo from '../components/HenkelLogo';
import PaddedContainer from '../components/PaddedContainer';
import TitleHeader from '../components/TitleHeader';
import '../css/safetyVideo.css';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function SafetyVideo() {
    const navigate = useNavigate();

    const [isVideoFinished, setIsVideoFinished] = useState(false);
    const videoRef = useRef(null);
    const timeRef = useRef(0);

    useEffect(() => {
        videoRef.current.addEventListener("timeupdate", () => {
            if (!videoRef.current.seeking) {
                timeRef.current = videoRef.current.currentTime;
            }
        });

        videoRef.current.addEventListener("seeking", () => {
            let delta = videoRef.current.currentTime - timeRef.current;
            if (delta > 0.01) {
                videoRef.current.currentTime = timeRef.current;
            }
        });

        videoRef.current.addEventListener("ended", () => {
            setIsVideoFinished(true);
        });

    }, [])

    return (
        <div className="safetyVideoContainer">
            <Helmet>
                <title>Safety Video</title>
                <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <HenkelLogo />
            <div className='leftAlignedContainer'>
                <PaddedContainer >
                    <TitleHeader
                        title={"Safety instruction video"}
                        content={"Please watch the video until the end to be able to download your access QR code."}
                    />
                    <div style={{ display: "flex", flexDirection: "column", height: "100%", flexGrow: "1", justifyContent: "space-between", width: "100%" }}>
                        <video src='/Example.mp4' ref={videoRef} autoPlay controls style={{ width: "100%", borderRadius: "12px" }}></video>

                        <button onClick={() => navigate("/qrdownload")} style={{ backgroundColor: isVideoFinished ? "#EC1B23" : "#EC1B23A9" }} disabled={!isVideoFinished} className='henkelButton'>Continue</button>
                    </div>

                </PaddedContainer>
            </div>

        </div>
    )
}

export default SafetyVideo;