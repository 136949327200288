import { Helmet } from 'react-helmet';
import HenkelLogo from '../components/HenkelLogo';
import PaddedContainer from '../components/PaddedContainer';
import TitleHeader from '../components/TitleHeader';
import '../css/qrpage.css';
import { useRef } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import QRCode from 'react-qr-code';

function QRDownloadPage() {

    const qrValue = localStorage.getItem("qrCodeUserInfo");
    const qrElementRef = useRef(null);

    const downloadPDF = async () => {
        const content = qrElementRef.current;

        const canvas = await html2canvas(content);

        const imgData = canvas.toDataURL("image/png");

        const pdf = new jsPDF();
        pdf.addImage(imgData, "PNG", 0, 0, 210, 150); 

        pdf.save("QRCodeHenkel.pdf");
    }

    return (
        <div className='qrPageContainer'>
            <Helmet>
                <title>Download QR Code</title>
                <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <HenkelLogo />
            <div className='leftAlignedContainer'>
                <PaddedContainer >
                    <TitleHeader
                        title={"Your Access QR Code "}
                        content={"Please present this QR code to the security officer at the reception so they can assist you."}
                    />
                    <div ref={qrElementRef} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "4em" }}>
                        <div style={{ border: "1px solid #EC1B23", borderRadius: "12px", padding: "16px" }}>
                            <QRCode value={qrValue} />
                        </div>
                    </div>
                    <button onClick={downloadPDF} className='henkelButton'>Download</button>
                </PaddedContainer>
            </div>
        </div>
    );
}

export default QRDownloadPage;