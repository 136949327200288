import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import PaddedContainer from "../components/PaddedContainer";
import HenkelLogo from "../components/HenkelLogo";
import '../css/formationDoc.css';
import LineSeparator from "../components/LineSeparator";
import StepList from "../components/StepList";

// TODO: Update this when content is available
const formationData = [
    {
        title: "Lototo",
        steps: ["step 1", "step 2", "step 3"],
        videoLink: "/Example.mp4"
    }
];

function FormationDoc() {

    const { id } = useParams();

    return (
        <div className="formationDocPageContainer">
            <Helmet>
                <title>Formation : {formationData[id].title}</title>
                <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <HenkelLogo />
            <div className="centerAlignedContainer">
                <PaddedContainer>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <h2>{formationData[id].title}</h2>
                    </div>
                    <LineSeparator color={"#EC1B23"} />
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
                        <video src={formationData[id].videoLink} autoPlay controls style={{ width: "100%", borderRadius: "12px" }}></video>
                    </div>
                    <LineSeparator color={"#EC1B23"} />
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "2em" }}>
                        <h2>{formationData[id].title} <span className="steps">Steps</span></h2>
                    </div>
                    <StepList steps={formationData[id].steps} />
                    {/* <ol className="numberedList">
                        <li className="listItem">Lock the automatic rotation before starting the experience.</li>
                        <li className="listItem">Ensure that background applications utilizing your camera are closed.</li>
                        <li className="listItem">Scan the QR code at the bottom of the calendar page with your camera app or your code scanner.</li>
                        <li className="listItem">Wait for the page to load.</li>
                        <li className="listItem">Allow access to the camera.</li>
                        <li className="listItem">Rotate your phone to the left for an optimized experience in landscape orientation.</li>
                        <li className="listItem">Click on the screen to activate the sound.</li>
                        <li className="listItem">Scan the image and enjoy the experience.</li>
                    </ol> */}
                </PaddedContainer>
            </div>
        </div>
    )
}

export default FormationDoc;