import { useState } from 'react';
import HenkelLogo from '../components/HenkelLogo';
import PaddedContainer from '../components/PaddedContainer';
import '../css/meetingForm.css';
import '../css/forms.css';
import HenkelInput from '../components/HenkelInput';
import { message } from 'react-message-popup';
import { useNavigate } from "react-router-dom";
import TitleHeader from '../components/TitleHeader';
import { Helmet } from 'react-helmet';

function MeetingForm() {

    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phoneNumber: "",
        hostName: "",
        purposeOfVisit: "",
        vehicleInfo: "",
        emergencyContact: "",
    });

    const SubmitForm = () => {
        if (!formData.name) {
            message.error("Name missing", 4000)
            return;
        }
        if (!formData.email) {
            message.error("Email missing", 4000)
            return;
        }
        if (!formData.phoneNumber) {
            message.error("Phone number missing", 4000)
            return;
        }
        if (!formData.hostName) {
            message.error("Host name missing", 4000)
            return;
        }
        if (!formData.purposeOfVisit) {
            message.error("Purpose of visit missing", 4000)
            return;
        }
        const infoString = Object.entries(formData)
            .filter(([key, value]) => value !== '' && value !== null && value !== undefined)
            .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
            .join('&');
        const QRLink = `https://henkel.nebultech.com/visitorinfo?${infoString}`;

        localStorage.setItem("qrCodeUserInfo", QRLink);
        navigate("/safetyVideo");
    }

    return (
        <div className="meetingFormContainer">
            <Helmet>
                <title>Access Form</title>
                <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <HenkelLogo />
            <div className='leftAlignedContainer'>
                <PaddedContainer>
                    <TitleHeader 
                        title={"Welcome, dear visitor"}
                        content={"Please fill out this form so we can provide you with your access QR code."}
                    />
                    <div>
                        <HenkelInput
                            type={"text"}
                            formProperty={"name"}
                            formData={formData}
                            setFormData={setFormData}
                            isRequired={true}
                            label={"Enter your full name"}
                            placeholder={"Full Name"}
                        />
                        <HenkelInput
                            type={"email"}
                            formProperty={"email"}
                            formData={formData}
                            setFormData={setFormData}
                            isRequired={true}
                            label={"Enter your email"}
                            placeholder={"email@gmail.com"}
                        />
                        <HenkelInput
                            type={"text"}
                            formProperty={"phoneNumber"}
                            formData={formData}
                            setFormData={setFormData}
                            isRequired={true}
                            label={"Enter your phone number"}
                            placeholder={"+213"}
                        />
                        <HenkelInput
                            type={"text"}
                            formProperty={"hostName"}
                            formData={formData}
                            setFormData={setFormData}
                            isRequired={true}
                            label={"Host Name"}
                            placeholder={"Person or department"}
                        />
                        <HenkelInput
                            type={"text"}
                            formProperty={"purposeOfVisit"}
                            formData={formData}
                            setFormData={setFormData}
                            isRequired={true}
                            label={"Purpose of visit"}
                            placeholder={"Meeting"}
                        />
                        <HenkelInput
                            type={"text"}
                            formProperty={"vehicleInfo"}
                            formData={formData}
                            setFormData={setFormData}
                            isRequired={false}
                            label={"Vehicle information"}
                            placeholder={"License Plate Number"}
                        />
                        <HenkelInput
                            type={"text"}
                            formProperty={"emergencyContact"}
                            formData={formData}
                            setFormData={setFormData}
                            isRequired={false}
                            label={"Emergency contact"}
                            placeholder={"+213"}
                        />

                        <button
                            className='henkelButton'
                            onClick={SubmitForm}
                        >Submit</button>
                    </div>
                </PaddedContainer>
            </div>
        </div>
    )
}

export default MeetingForm;